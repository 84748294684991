<template>
	<div class="badges">
		<template v-for="(badge, index) in badges">
			<transition name="translate-down">
				<Badge :badge="badge" :key="index" v-if="badgeIndex == index"/>
			</transition>
		</template>
	</div>
</template>

<script>
import Badge from "@/Components/Product/ProductComponents/Badge.vue";
export default {
	name: "Badges",
	props : {
		badges: {
			type: Object,
			required: true
		},
	},
	components : {
		Badge
	},
	data(){
		return {
			badgeIndex : -1
		}
	},
	mounted(){

    // Make first badge appear within
		setTimeout(() => {
			this.badgeIndex = 0;
		}, Math.random(100, 500));

    if (this.badges.length > 1) {

      let i = 1;

      setInterval(() => {
        this.badgeIndex = i;
        i++;
        if(i == this.badges.length){
          i = 0;
        }
      }, 5000);

    }

	}
}
</script>

<style scoped>

</style>