<script setup>

import { getCurrentInstance, onMounted, ref } from "vue";
import { store as storeConfig } from "~app_root/public/tv/app.config";

import { Link } from '@inertiajs/vue3'
import { scrollTop } from "@/utility";
import FavoriteButton from "./ProductComponents/FavoriteButton.vue";
import BuyButton from "./ProductComponents/BuyButton.vue";
import QuantityControl from "@/Components/ECart/Reusable/QuantityControl.vue";
import DeleteConfirmation from "@/Components/ECart/Reusable/DeleteConfirmation.vue";
import ReviewsSummary from "@/Components/Product/ReviewsSummary.vue";
import Badges from "@/Components/Product/ProductComponents/Badges.vue";
import { useCartItem } from "@/Composables/useCartItem";

const appInstance = getCurrentInstance();
const emitter = appInstance.appContext.config.globalProperties.emitter;

const props = defineProps({
	product: {
		type: Object,
		required: true
	},
	product_key: {
		type: Number,
		required: true
	}
});

const badgeGroups = ref({});

function populateBadgeGroups() {

	if (!props.product.badges?.length) return false;

	const groups = {};

	props.product.badges.forEach((badge) => {
		if (!groups[badge.group]) {
			groups[badge.group] = [];
		}
		groups[badge.group].push(badge);
	});

	badgeGroups.value = groups; // Properly update the ref

}

onMounted(() => {
	populateBadgeGroups();
});


const productTitle = props.product.title.length < 50 ? props.product.title : props.product.title.substring(0, 50) + "...";
const discount = 100 - Math.round(props.product.price_new * 100 / props.product.price_old);

const cartItem = useCartItem(props.product.uuid);

const clickedDelete = () => {
	emitter.emit("cartItemDeleteButtonClicked", cartItem.uuid.value);
};

</script>

<template>
	<div :class="{ 'is-in-cart': cartItem.quantity.value }" class="product-tile">
		<div>

			<FavoriteButton :product_id="product.id"></FavoriteButton>

			<DeleteConfirmation :item_uuid="cartItem.uuid.value ?? null" />

			<button v-if="storeConfig.add_to_cart_quantity_controls && cartItem.quantity.value" aria-label="Delete"
				class="delete-product" @click="clickedDelete()">
				<i class="lnr icon-trash"></i>
			</button>

			<Link :alt="product.title" :href="route('catchall', product.slug, false)" :onFinish="scrollTop">
			<span v-if="product.price_new && product.price_old" :title="'Discount ' + discount + '%'"
				class="discount-label">-{{ discount
				}}%</span>
			<div class="product-image-wrapper">
				<img :src="'/storage/product_image' + product.image" alt="" class="product_image">
			</div>
			<div class="product-name-wrapper">
				<p :title="productTitle" class="product-name">{{ productTitle }}</p>
			</div>

			<ReviewsSummary :average_rating="product.average_rating || 0" :total_reviews="product.total_reviews || 0"
				v-if="!Object.keys(badgeGroups).length || product.total_reviews" />

			<Badges v-for="(badgeGroup, badgeGroupName) in badgeGroups" v-if="Object.keys(badgeGroups).length"
				:badges="badgeGroup" :class="{
					'tag': badgeGroupName === 'tag',
					'bubble': badgeGroupName === 'bubble',
				}" />
			</Link>

			<div class="bottom-wrapper">

				<div v-if="product.price_new"
					class="prices d-flex flex-column justify-content-center align-items-center">
					<span v-if="product.price_old > product.price_new" class="old-price me-2"> {{
						product.price_old.toFixed(2) }} {{ $page.props.store.currency_symbol ||
							$page.props.store.currency_code }} </span>
					<span class="price">{{ product.price_new.toFixed(2) }} {{ $page.props.store.currency_symbol ||
						$page.props.store.currency_code }}</span>
				</div>

				<div v-if="storeConfig.product_tile_button_template == 'button' && product.price_new"
					class="product-buttons d-flex justify-content-between mb-3">

					<div v-if="product.stock" class="position-relative">

						<div v-if="storeConfig.add_to_cart_quantity_controls && cartItem.quantity.value">
							<QuantityControl v-model="cartItem.quantity.value"
								:max="storeConfig.product_max_quantity" />
						</div>

						<BuyButton v-else :product_uuid="product.uuid" :quantity="1"
							:show_popup="storeConfig.add_to_cart_confirmation_popup"></BuyButton>

					</div>

					<button v-else class="buy-btn flex-fill font-semibold text-white" disabled>
						<span class="cta_text">
							{{ __("out_of_stock") }}
						</span>
					</button>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ProductTile"
};
</script>

<style scoped></style>