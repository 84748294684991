<template>
	<button :id="'buyButtonProductUUID_' + product_uuid" class="btn buy-btn flex-fill"
		:class="status + ' ' + extra_classes" @click="addProductToCart()">
		<span class="cta_text text-nowrap d-flex align-items-center justify-content-center gap-2">
			<i class="lnr cart" :class="icon"></i>
			<span class="ms-1">{{ __(button_text) }}</span>
		</span>
		<span class="loading_text"> <i class="lnr icon-hourglass"></i> {{ __('loading') }}</span>
		<span class="done_text"> <i class="lnr icon-check"></i> {{ __('added_to_cart') }}</span>
	</button>
</template>

<script>
export default {
	name: "BuyButton",
	props: {
		button_text: {
			type: String,
			required: false,
			default: 'add_to_cart'
		},
		quantity: {
			type: Number,
			required: false,
			default: 1
		},
		product_uuid: {
			type: String,
			required: true
		},
		show_popup: {
			type: Boolean,
			required: false,
			default: true
		},
		extra_classes: {
			type: String,
			required: false,
			default: ''
		},
		icon: {
			type: String,
			required: false,
			default: 'icon-cart'
		},
		visitUrlAfterAddToCart: {
			type: String,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			status: 'idle'
		}
	},
	methods: {

		addProductToCart() {

			//Prevent multiple clicks while the request is running
			if (this.status !== 'idle') return;

			this.status = 'loading';

			this.$store.dispatch('cart/addItem', {
				product_uuid: this.product_uuid,
				quantity: this.quantity,
				show_popup: this.show_popup,
				visitUrl: this.visitUrlAfterAddToCart
			}).then(response => {

				this.status = 'done';

				setTimeout(() => {
					this.status = 'idle'
				}, 1000)

			});

		}

	},

}
</script>

<style scoped></style>