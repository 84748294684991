<template>
	<div class="badge" :style="{
		'background-color' : badge.background_color + ' !important'
	}" :key="index">

    <img :src="'/images_generic/icons/' + badge.icon" class="icon">

    <span :style="{
		'color' : badge.color + ' !important'
	}">
	    {{badge.title}}
    </span>

	<!--  Keep this to prevent purge css from deleting the classes  -->
	<div class="tag bubble d-none"></div>

  </div>
</template>

<script>
export default {
	name: "Badge",
	props : ['badge']
}
</script>

<style scoped>

</style>