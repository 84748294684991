
// Site variables
export let site = {
    name : 'Bagsy',
    motto : {
        ro : 'Love for bags',
        en : 'Love for bags'
    },
    pages : {
        home : {
            title : {
                ro : 'Magazin online de genți, rucsacuri si accesorii',
            },
            description : {
                ro : 'Vă oferim o selecție largă de genți și portofele pentru femei și bărbați. Indiferent căutați o geantă elegantă pentru ocazii speciale sau o geantă de zi cu zi, un rucsac sau un portofel clasic pentru bărbați, suntem siguri că veți găsi ceva pe gustul dumneavoastră în magazinul nostru online. Oferim produse de calitate superioară la prețuri accesibile, iar livrarea este rapidă și gratuită pentru comenzile peste o anumită valoare. Vă stăm la dispoziție cu consultanță și ajutor în găsirea produselor perfecte pentru dumneavoastră sau pentru a face un cadou special pentru cei dragi.',
            }
        }
    },
    about : {
        ro : 'Bagsy a fost creat din pasiunea pentru genți și accesorii. Ne dorim să oferim produse de calitate superioară la prețuri accesibile, iar livrarea să fie rapidă și gratuită pentru comenzile peste o anumită valoare.',
        en : 'Bagsy was created from the passion for bags and accessories. We want to offer superior quality products at affordable prices, and delivery to be fast and free for orders over a certain value.'
    },
    showNewsletterPopup: false,
    showNewsletterDiscountText: true,
}

// Site languages
export let languages = [
    {
        iso_693_1_code: 'ro',
        iso_name: 'Romana',
        flag : '<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-ro" viewBox="0 0 512 512"><g fill-rule="evenodd" stroke-width="1pt"><path fill="#00319c" d="M0 0h170.7v512H0z"/><path fill="#ffde00" d="M170.7 0h170.6v512H170.7z"/><path fill="#de2110" d="M341.3 0H512v512H341.3z"/></g></svg>'
    },
];

// 2Performant
export let tooperformant = {
    enabled : false,
    campaign_unique : '',
    confirm : ''
}

// Store configuration
export let store = {
    product_max_quantity : 10000,
    limitMaxQuantity(value){
        return (value > this.product_max_quantity) ? this.product_max_quantity : value
    },
    gift_wrap_uuid : '2933ec47-b4eb-315c-8b2f-85d4348f',
    free_shipping_threshold : 50,
    product_tile_button_template : 'button',
    add_to_cart_confirmation_popup : true,
    add_to_cart_quantity_controls : false,
    clear_cart_button : false,
    checkout_type : 'three_step_checkout',
    checkout_hide_shipping: false,
    checkout_show_vat : false,
    product_page : {
        product_details_tabs : [
            'description','specs','faq'
        ],
        show_discreet_shipping: true,
        show_shipping_cost : true,
        show_phone_numbers : true,
		allow_price_zero_purchases: true
    }
}

export let footer_navigation_1_data = {
    'ro' : [
        {
            title : 'Detalii livrare',
            route : {
                name : null,
                slug : 'detalii-livrare'
            },
            children : []
        },
        {
            title : 'Retur si Garantie',
            route : {
                name : null,
                slug : 'retur-si-garantie'
            },
            children : []
        },
        {
            title : 'Rezolvarea alternativa a litigiilor',
            route : {
                name : null,
                slug : null,
                url : 'https://anpc.ro/ce-este-sal/'
            },
            children : []
        },
        {
            title : 'Rezolvarea online a litigiilor',
            route : {
                name : null,
                slug : null,
                url : 'https://anpc.ro/ce-este-sal/'
            },
            children : []
        },
    ],
    'en' : [
        {
            title : 'Delivery details',
            route : {
                name : null,
                slug : 'delivery-details'
            },
            children : []
        },
        {
            title : 'Return and Warranty',
            route : {
                name : null,
                slug : 'return-and-warranty'
            },
            children : []
        },
        {
            title : 'Alternative dispute resolution',
            route : {
                name : null,
                slug : null,
                url : 'https://anpc.ro/ce-este-sal/'
            },
            children : []
        },
        {
            title : 'Online dispute resolution',
            route : {
                name : null,
                slug : null,
                url : 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'
            },
            children : []
        },
    ]
}


export let footer_navigation_2_data = {
    'ro' : [
        {
            title : 'FAQ',
            route : {
                name : null,
                slug : 'faq'
            },
            children : []
        },
        {
            title : 'Contactati-ne',
            route : {
                name : null,
                slug : 'contact'
            },
            children : []
        },
        {
            title : 'Termeni si conditii',
            route : {
                name : null,
                slug : 'termeni-si-conditii'
            },
            children : []
        },
        {
            title : 'Date cu caracter personal',
            route : {
                name : null,
                slug : 'date-cu-caracter-personal'
            },
            children : []
        }
    ],
    'en' : [
        {
            title : 'FAQ',
            route : {
                name : null,
                slug : 'faq'
            },
            children : []
        },
        {
            title : 'Contact us',
            route : {
                name : null,
                slug : 'contact-us'
            },
            children : []
        },
        {
            title : 'Terms and conditions',
            route : {
                name : null,
                slug : 'terms-and-conditions'
            },
            children : []
        },
        {
            title : 'Personal data',
            route : {
                name : null,
                slug : 'personal-data'
            },
            children : []
        }
    ]
}

export let contact_information = {

    email : 'comenzi@bagsy.ro',

    phones : [
        {
            'label' : '0376.448.041',
            'value' : '0376448041'
        },
        {
            'label' : '0730.556.787',
            'value' : '0730556787'
        }
    ],
    whatsapp_phone_number: '+40730556787',
    show_whatsapp_button: true,
    program : {
        ro : 'Zilnic 8:30 - 20:30',
        en : 'Daily 8:30 - 20:30'
    },
}